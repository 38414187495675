import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import Phone from "../../images/icons/phone.svg"

import "./author-info.scss"

const AuthorInfo = () => {
  const imageAravind2 = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "aravind-thumb.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div className="author-info">
      <Image
        className="info-block__admin-image"
        fluid={imageAravind2.file.childImageSharp.fluid}
        alt="Aravind Karavattu"
      />
      <p>
        <Link
          to="/administrator"
          className="link_text"
          title="Aravind Karavattu - The Administrator Page"
          tabIndex="0"
        >
          Aravind Karavattu
        </Link>{" "}
        hails from a reputed family of astrologers and he represents the fourth
        generation. Aravind was intrigued by the influence of the solar system
        in our lives and the legends about his ancestors, and he set out on the
        quest of finding answers at the age of 16.
      </p>
      <p>
        Aravind believes that the knowledge of astrology should be shared with
        the public than to be kept in a closed circle, hence he shares his
        knowledge through talks and articles.
      </p>
      <a
        className="button_contact"
        href="tel:+919567752287"
        title={"Click to call +91 956 775 2287"}
        style={{ marginBottom: ".5rem" }}
      >
        <img src={Phone} alt="" className="button_contact__icon"></img>
        {"+919567752287".replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1-$2-$3-$4")}
      </a>
      <a
        title="Link to the Facebook page Astrologer Aravind"
        href="https://www.facebook.com/aravindastrologer"
        target="_blank"
        rel="noreferrer"
        className="button_facebook"
      >
        <svg
          viewBox="0 0 36 36"
          className="a8c37x1j ms05siws hwsy1cff b7h9ocf4"
          fill="url(#jsc_s_2)"
          height="40"
          width="40"
        >
          <defs>
            <linearGradient
              x1="50%"
              x2="50%"
              y1="97.0782153%"
              y2="0%"
              id="jsc_s_2"
            >
              <stop offset="0%" stopColor="#0062E0"></stop>
              <stop offset="100%" stopColor="#19AFFF"></stop>
            </linearGradient>
          </defs>
          <path d="M15 35.8C6.5 34.3 0 26.9 0 18 0 8.1 8.1 0 18 0s18 8.1 18 18c0 8.9-6.5 16.3-15 17.8l-1-.8h-4l-1 .8z"></path>
          <path
            className="p361ku9c"
            fill="#fff"
            d="M25 23l.8-5H21v-3.5c0-1.4.5-2.5 2.7-2.5H26V7.4c-1.3-.2-2.7-.4-4-.4-4.1 0-7 2.5-7 7v4h-4.5v5H15v12.7c1 .2 2 .3 3 .3s2-.1 3-.3V23h4z"
          ></path>
        </svg>
        fb.com/aravindastrologer
      </a>
    </div>
  )
}

export default AuthorInfo
