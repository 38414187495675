import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import ScrollProgressRead from "react-scroll-progress-read"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import AuthorInfo from "../../components/author-info/author-info"

import ServicesList from "../../components/services-list/services-list"
import BlogList from "../../components/blog-list/blog-list"

import "../../styles/blog.scss"

const AdministratorPage = () => {
  const imageArticle = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "yantra-blog.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Yanthras – Astrologer Aravind | Ashtamangalaprasnam Blog"
        description="Yanthras are the secret keys for establishing resonance with the energies of the macrocosm, for your all round protection."
        keyword="Yanthra, yantra, astrology, sin, wealth, money, health, Sarvavashya, Aghora, Mruthasanjeevani, Vidya Rajagopala, Kubera, BaglaMukhi, Maha, Swayamvara Parvathi, Mrityunjaya, Saraswati, Shoolini Durga, Sudarshana"
        image="../../images/yantra-blog.jpg"
      />
      <div className="scroll-progress">
        <ScrollProgressRead
          backgroundColor="#CCC"
          barColor="#931a1a"
          height="5px"
          target="article"
        />
      </div>
      <section id="article" className="article">
        <section className="article-header">
          <Image
            className="article-header__image"
            fluid={imageArticle.file.childImageSharp.fluid}
            alt="Maha Ganapati yanthra"
          />
          <div className="article-header__text-wrap article-header__text-wrap_gold">
            <h1 className="article__heading">Yanthras</h1>
            <p className="article__description">
              The secret keys for establishing resonance with the energies of
              the macrocosm, for your all round protection.
            </p>
          </div>
        </section>
        <section className="container article-wrap">
          <article className="article__content">
            <p>
              ‘Yamanaath traayathe ithi Yantraha’ – Yantra is one which protects
              from troubles. A Yantra depicts both macrocosmic and microcosmic
              forces acting together – the movement towards and away from the
              center – “control” and “liberation” within the one device.
            </p>
            <p>
              Yantra is constructed by observing immutable laws of sacred
              geometry, with an interlocking matrix of geometric figures,
              circles, triangles, etc. This is later energized by Siddha mantra
              thus infusing the specific energy to Yantra. Yantra is a highly
              efficient tool for contemplation, concentration and meditation.
              They carry spiritual significance and point the user to higher
              levels of consciousness. Yantras are tools to enhance our life in
              different dimensions. Hindu shastras designate this Yantra as the
              most important one as it has a powerful effect on the attainment
              of power, authority and financial success.
            </p>
            <p>
              Yantras represent the body of Gods and the mantras represent prana
              (life) of the Gods. When we chant the mantra associated with a
              Yantra while wearing the Yantra while following Tantras we
              activate the Yantra.
            </p>
            <p>
              Very often the Yantras can put in contact with extremely elevated
              energies and entities, being of invaluable help on the spiritual
              path system, reflecting on a large scale one of its component
              systems or parts. Yantras often focus on a specific deity and so
              by tuning into the different Yantras. One can interact with
              certain deities or creative force centres in the universe by the
              Yantras.
            </p>

            <p>Yantras with great astrologcal importance are listed below.</p>

            <div className="article-list-item">
              <h2 className="h4">Maha Sudarshana Yantra</h2>
              <p>
                Maha Sudarshana Yantra is the prime protective Yantra.
                Sudarshana resembles the powerful weapon of Lord Vishnu. This
                dynamic Yantra diminishes all illness, negativity, misfortune,
                accidents and many more negativities of life, keeps its
                worshippers safe, and protected from all damage that they may
                arrive across. It enhances the peace of mind and consciousness,
                helps to earn favors from women, officials and Government, and
                wealth, agricultural prosperity, and fame can be easily achieved
                by such persons.
              </p>
              <p>
                The worshipper of this Yantra is protected from the problems
                signified by the Sun like weak eyesight, headache, disturbance
                of blood circulation, bone weaknesses, palpitation of heart and
                inflammatory conditions etc, and will also be blessed by the
                power and dominance associated with the Sun.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Shoolini Durga Yantra</h2>
              <p>
                The Shoolini Durga Yantra is the most potent Yantra. The
                presence of Trishuls or Tridents marked in the Yantra in all
                directions with Beeja and moola mantras on the Yantra gives it
                the strength to seek protection for the owner and keeps him/her
                safe from all possible dangers- man-made or fate-ridden when
                properly energized.
              </p>
              <p>
                This Yantra offers protection against several unseen, unknown
                evils- evil eye, jealousy, hidden enemies, etc and is very
                effective for the removal of witchcraft and the protection of
                the household from black magic and evil designs of enemies. More
                so the Shoolini Durga Kavach Yantra is a powerful Yantra for
                attaining our heart's desires, to remove difficulties, to
                conquer enemies, it also bestows wealth and property and protect
                the person from all sorts of perils. Keeping this Yantra at the
                main door of a South facing door eliminates all types of Vastu
                Doshas.
              </p>
              <p>
                The Pooja, Prana- Prathishta and daily cleansing is done for
                Keerthi Labha (fame), Shathru Nasha (protection from enemies),
                Vidhya Vriddhi (improvement in studies) and Improvement in
                business (Vyapaar Vriddhi)
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Saraswati Yantra</h2>
              <p>
                Goddess Saraswati bestows intellect, intelligence and is the
                patron goddess of learning and music. Hence, those who worship
                this Yantra will be blessed with a sharp intellect, fast
                learning power, long lasting memory, better concentration,
                increased knowledge, boosted understanding and appreciation and
                success in the fields of creative arts, especially fine arts and
                music. It is a recognized cure for psychological disorders, weak
                intellectual growth and lunacy.
              </p>
              <p>
                This Yantra blesses the user with success in examinations and
                concentration in the study. This Yantra is a must for those who
                are poor at learning or have suffered breaks in their education
                and for those who are suffering from the bad effects of a
                malefic Jupiter. This Yantra ensures success in studies and high
                achievement in competitive examinations. In addition, Saraswati
                Yantra has the power to bless married women.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Maha Mrityunjaya Yantra</h2>
              <p>
                Maha Mrityunjaya Yantra is dedicated to Lord Shiva and it is
                said to be the abode of Lord Shiva. It gives protection from
                untimely death and offers recovery from deadly diseases.
                Worshippers of this Yantra are blessed with health, wealth,
                happiness, good fortune, prosperity, victory etc. and is freed
                from evil effects, fear of ghosts, accidental death and
                diseases, troubles, tensions, stress, miseries, enemies and
                troubles of life. It also provides wearer defense from the evil
                activities of wicked ghosts and influence of black magic.
              </p>
              <p>
                This Yantra is also very helpful in pacifying the negative
                effects of planets Rahu and Ketu if they are wrongly placed or
                are forming any bad Yoga like Kaal Sarp Dosha in a person's
                horoscope.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Swayamvara Parvathi Yantra</h2>
              <p>
                Goddess Parvathi is the Goddess of Love and Devotion and she
                gifts the singles who worship her with their life partners, she
                also soothes the minds of couples and paves the way for gracious
                lives together and everlasting happiness. This Yantra guides her
                blessings towards the worshipper. Married life will always be a
                heaven on earth for those who worship this Yantra!
              </p>
              <p>
                Worshipping this Yantra solves the delay and hurdles in entering
                into marriage, infertility, problems during pregnancy, fights
                and misunderstandings between couples, it also helps to attain a
                spouse of choice, to choose an ideal spouse, to rejuvenate
                marriage and it can also help one support marriage of a family
                member.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Bagla-Mukhi Maha Yantra</h2>
              <p>
                Bagla-Mukhi Devi is the goddess of courage, power, and strength,
                and is said to protect Earth from evil forces and black magic.
                It is believed that the goddess herself resides in this Yantra.
              </p>
              <p>
                This Yantra provides immense power and courage to the devotees
                to get success in every aspect of life, gives relief from every
                kind of sufferings of life, whether it may be caused by evil
                spirits or superficial obstacles. When worshiped with great
                devotion this Yantra can help one attain freedom from the cycle
                of life and death, thus providing salvation. The worshipper is
                rewarded with victory over enemies, competitors, in lawsuits, in
                debates and in competitions while worshipping this Yantra. This
                Yantra provides the safeguard against accidents.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Kubera Yantra</h2>
              <p>
                Lord Kubera is the Controller of the entire wealth of the
                universe and he can bless a person with unlimited wealth,
                prosperity, and riches if the person pleases him. The 27 lucky
                lines engraved in the Yantra ensures that it improves the luck
                of the person who is worshipping it. Those who worship Lord
                Kubera through this Yantra, are bound to become prosperous and
                wealthy. This Yantra opens up avenues for new sources of income,
                increases fund flow and boosts accumulation of wealth. This
                Yantra is very effective for growth in business, career, and
                profession and it confers the worshipper with wealth,
                prosperity, and good fortune. If a person is encountering
                continuous financial hardships and not all his efforts are
                bearing any fruits, then he should establish a Kubera Yantra and
                pray to Lord Kubera through it.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Vidya Rajagopala Yantra</h2>
              <p>
                Vidya Rajagopala Yantra is the king among Yantras. Education is
                one of the prime goals of life and it is the key to success in
                career, which ultimately leads to a happy and a prosperous life.
                However, there are times when even the best effort does not
                bring good results and the students might even face situations,
                which might even put an end to their education.
              </p>
              <p>
                To achieve a better education without any kind of interruption,
                this Yantra should be installed and worshiped. This occult
                Yantra refines the intellect, enhances the grasping power,
                increases memorization &amp; concentration. It is a must have
                for all those who are dull-witted or have suffered breaks in
                their education. This Yantra assures success in studies and high
                achievement in competitive examinations. By constant worship of
                this Yantra, one becomes knowledgeable, famous and gets
                appreciation from all.
              </p>
              <p>
                This Yantra is also ideal for those suffering from the bad
                effects of a malefic Jupiter.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Mruthasanjeevani Yantra</h2>
              <p>
                Mruthasanjeevani Yantra holds Lord Shiva’s divine power and
                offers both physical and psychological health and blesses
                worshipper with wealth, good fortune, fame, and happiness. This
                must be worn by devotees who face premature death.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Aghora Yantra</h2>
              <p>
                It is one of the most powerful Shaiva Yantras. It offers
                courage, the power to the devotee. This Yantra successfully
                checks adverse influences of planets, the evils of enmity and
                ill effects of black magic.
              </p>
            </div>
            <div className="article-list-item">
              <h2 className="h4">Sarvavashya Yantra</h2>
              <p>
                Sarvavashya Yantra attracts the respect and due consideration
                everybody. Social activists, stage artists, political workers,
                etc. are advised to wear this Yantra. Those who need to impress
                or appease superiors are advised to wear this.
              </p>
            </div>
            <p>
              If you have any queries{" "}
              <Link
                to="/#contact-us"
                title="Get in touch with us"
                className="link_text"
              >
                click here
              </Link>{" "}
              to contact us.
            </p>
          </article>

          <aside className="article-sidebar">
            <AuthorInfo></AuthorInfo>
          </aside>
        </section>
      </section>
      <ServicesList cssClass="blog" />
      <BlogList cssClass="blog" />
    </Layout>
  )
}

export default AdministratorPage
